import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { obtenerProyectos } from "../../_actions/formularioActions";
import moment from "moment";
const Step_two = (props) => {

  const {datos,onChangeValue,onChangeImage, setFechaInicio, setFechaFin,prevStep,nextStep} = props;
  const [proyectos, setProyectos] = useState(null)
  const dispatch = useDispatch();
  const { 
          tipo_de_compra,
          fecha_inicio_membresia,
          fecha_fin_membresia,
          proyecto,
          modalidad,
          inversion,
          metodo_pago,
          monto_pago,
          n_operacion,
          nombre_facturacion,
          razon_social,
          tipo_comprobante,
          ruc} = datos;


    const handleSubmit = (e)=>{
      e.preventDefault()
      nextStep()
    }

    useEffect(()=>{
      async function fetchData() {
        // You can await here
        const response = await dispatch(obtenerProyectos());
        setProyectos(response);
        // ...
      }
      fetchData();
    },[])

    useEffect(()=>{
      
      if(tipo_de_compra==="plan")
      {
        setFechaInicio(moment().format('YYYY-MM-DD'))
      }
      else
      {
        setFechaInicio("")
      }
    },[tipo_de_compra])
    
    useEffect(()=>{
      
      if(fecha_inicio_membresia!=="")
      {
        if(tipo_de_compra==="plan")
        {
          setFechaFin(moment().add(1,'year').format('YYYY-MM-DD'))
        }
        else if(tipo_de_compra==="curso-taller")
        {
          setFechaFin(moment().add(2,'month').format('YYYY-MM-DD'))
        }
      }
      else{
        setFechaFin("")
      }
    },[fecha_inicio_membresia])

    const mostrarOpciones = opciones =>{
      if(opciones)
      {

        const filtrado = tipo_de_compra==="curso-taller" ? opciones.filter((opt)=>opt.nombre_proyecto.toLowerCase().includes('curso')) : opciones.filter((opt)=>!opt.nombre_proyecto.toLowerCase().includes('curso'))

        return  filtrado.map((opcion,i)=>(
          <option key={i} value={opcion.id_proyecto}>{opcion.nombre_proyecto}</option>
        ))
      }
    }

 
  return (
      <form onSubmit={handleSubmit}>
      <div className="PerfilUsuario">
        <div className="content_datos py-1 px-1">
          <Row>
            <div className="col-lg-12 order-lg-2">
              <div className="tab-content pt-1">
                <div className="" id="edit">
                
                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label form-control-label">
                      Tipo de compra:* 
                      </label>
                      <div className="col-lg-9">
                      <select
                          className="form-control"
                          name="tipo_de_compra"
                          value={tipo_de_compra}
                          onChange={onChangeValue}
                          required
                        >
                        <option value="">Seleccione una opción</option>
                        <option value="plan">Planes. Suscripción de hasta 12 meses.</option>
                        <option value="programa">Programas. Especializaciones de hasta 6 meses. </option>
                        <option value="curso-taller">Cursos y talleres. Capacitaciones de hasta 2 meses.</option>
                        </select>
                      </div>
                    </div>

                   { tipo_de_compra==="programa" || tipo_de_compra==="curso-taller"  ?   
                     <div className="form-group row">
                      <label className="col-lg-3 col-form-label form-control-label">
                      {tipo_de_compra==="programa"?'Programa':'Curso'}:*
                      </label>
                      <div className="col-lg-9">
                        <select
                            className="form-control"
                            name="proyecto"
                            value={proyecto}
                            onChange={onChangeValue}
                            required
                          >
                          <option value="">Seleccione un servicio...</option>
                          {proyectos ? mostrarOpciones(proyectos): ''}
                          </select>
                      </div>
                    </div>
                    : tipo_de_compra==="plan" ?
                    <>
                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label form-control-label">
                        Fecha Inicio:
                      </label>
                      <div className="col-lg-9">
                        <input
                          className="form-control"
                          type="date"
                          name="fecha_inicio_membresia"
                          value={fecha_inicio_membresia}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label form-control-label">
                        Fecha Fin:
                      </label>
                      <div className="col-lg-9">
                        <input
                          className="form-control"
                          type="date"
                          name="fecha_fin_membresia"
                          value={fecha_fin_membresia}
                          readOnly
                        />
                      </div>
                    </div>
                    </> : ''
                    }

                  { /* <div className="form-group row">
                      <label className="col-lg-3 col-form-label form-control-label">
                      Modalidad:* 
                      </label>
                      <div className="col-lg-9">
                      <select
                          className="form-control"
                          name="modalidad"
                          value={modalidad}
                          onChange={onChangeValue}
                          required
                        >
                        <option value="">Seleccione una modalidad</option>
                        <option value="presencial">Presencial</option>
                        <option value="online">Online</option>
                        </select>
                      </div>
                    </div>*/}
                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label form-control-label">
                      Forma de inversión:*
                      </label>
                      <div className="col-lg-9">
                      <select
                          className="form-control"
                          name="inversion"
                          value={inversion}
                          onChange={onChangeValue}
                          required
                        >
                          <option value="">Seleccione una opción</option>
                          <option value="contado">Contado (1 abono)</option>
                          <option value="cuotas">Semicontado (2 abonos - el segundo en 30 días)</option>
                          <option value="mensualidades">En cuotas (6 abonos - Todos los días 30 por adelantado)</option>
                      </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-12 col-form-label form-control-label">
                        Método de pago:* 
                      </label>
                      <div style={{display:'flex', flexDirection:'column', paddingLeft:'30px'}} className="col-lg-12 form-check" onChange={onChangeValue}>
                          <div> <input id="optw" className="form-check-input" type="radio" value="1" name="metodo_pago" defaultChecked={metodo_pago==='1' ? true : false} required /> <label htmlFor={'optw'}> Desde la web <a href="https://inedi.edu.pe/programas/" target="_blank" rel="nofollow">Gestora</a> con tarjeta de crédito o débito. </label> </div> 
                          <div> <input id="optap" className="form-check-input" type="radio" value="2" name="metodo_pago" defaultChecked={metodo_pago==='2' ? true : false} required /> <label htmlFor={'optap'}> Desde el <a href="https://www.viabcp.com/canales/banca-movil" target="_blank" rel="nofollow">BCP</a> web o APP en Pagar servicios - Gestora.  </label> </div>
                          <div> <input id="optag" className="form-check-input" type="radio" value="3" name="metodo_pago" defaultChecked={metodo_pago==='3' ? true : false} required /> <label htmlFor={'optag'}> Desde <a href="https://www.viabcp.com/canales-presenciales" target="_blank" rel="nofollow">un agente BCP</a> con el código 23287. </label> </div>
                          <div> <input id="optti" className="form-check-input" type="radio" value="4" name="metodo_pago" defaultChecked={metodo_pago==='4' ? true : false} required /> <label htmlFor={'optti'}> Desde otros bancos a nuestro CCI: 00238000258307504842 </label> </div> 
                      </div>

                    {  /*<ul>
                        <li>
                          <a href="https://inedi.edu.pe/programas/" target="_blank" rel="nofollow">Accede a la web de INEDI aquí.</a>
                        </li>
                        <li>
                          <a href="https://www.viabcp.com/canales-presenciales" target="_blank" rel="nofollow">Conoce los agentes presenciales del BCP aquí.</a>
                        </li>
                        <li>
                          <a href="https://www.viabcp.com/canales/banca-movil" target="_blank" rel="nofollow">Accede a la banca móvil del BCP aquí.</a>
                        </li>
                      </ul>*/}
                    </div>

                   { /*<div className="form-group row">
                      <label className="col-lg-3 col-form-label form-control-label">
                        Monto inversión:*
                      </label>
                      <div className="col-lg-9">
                        <input
                          className="form-control"
                          type="number"
                          name="monto_pago"
                          placeholder="Monto Inversión"
                          value={monto_pago}
                          onChange={onChangeValue}
                        />
                      </div>
                    </div>*/}
                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label form-control-label">
                        Foto/Captura de la compra:*
                      </label>
                      <div className="col-lg-9">
                        <input
                          className="form-control"
                          name="voucher"
                          type="file"
                          onChange={onChangeImage}
                          required
                        />
                      </div>
                    </div>
                  {  /*<div className="form-group row">
                      <label className="col-lg-3 col-form-label form-control-label">
                        Número de Operación:*
                      </label>
                      <div className="col-lg-9">
                        <input
                          className="form-control"
                          name="n_operacion"
                          type="number"
                          value={n_operacion}
                          onChange={onChangeValue}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label form-control-label">
                        Sirvase facturar a nombre de:
                      </label>
                      <div className="col-lg-9">
                        <input
                          className="form-control"
                          type="text"
                          name="nombre_facturacion"
                          placeholder="Ejm: Av. La Victoria #225"
                          value={nombre_facturacion}
                          onChange={onChangeValue}
                          required
                        />
                      </div>
                    </div>*/
                    }
                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label form-control-label">
                       Tipo de comprobante:
                      </label>
                      <div style={{display:'flex', flexDirection:'column', paddingLeft:'30px'}} className="col-lg-9 form-check" onChange={onChangeValue}>
                          <div> <input id="optb" className="form-check-input" type="radio" value="1" name="tipo_comprobante" defaultChecked={tipo_comprobante==='1' ? true : false} required /> <label htmlFor={'optb'}>Boleta </label></div> 
                          <div> <input id="optf" className="form-check-input" type="radio" value="2" name="tipo_comprobante" defaultChecked={tipo_comprobante==='2' ? true : false} required /> <label htmlFor={'optf'}>Factura </label></div>
                      </div>
                    </div>
                 { tipo_comprobante==='2' ? <>  <div className="form-group row">
                      <label className="col-lg-3 col-form-label form-control-label">
                        Razon Social:*
                      </label>
                      <div className="col-lg-9">
                      <input
                          className="form-control"
                          type="text"
                          name="nombre_facturacion"
                          placeholder="Empresa SAC"
                          value={nombre_facturacion}
                          onChange={onChangeValue}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-3 col-form-label form-control-label">
                        N° RUC:*
                      </label>
                      <div className="col-lg-9">
                        <input
                          className="form-control"
                          type="text"
                          name="ruc"
                          placeholder="N° de RUC"
                          value={ruc}
                          onChange={onChangeValue}
                          pattern="[0-9]{11}"
                          title="El número debe tener 11 dígitos"
                          required
                        />
                      </div>
                    </div></> : null}
                    <div className="form-group row d-flex justify-content-between">
                      <button className="btn btn-primary" onClick={()=>prevStep()} >Anterior</button>
                      <button className="btn btn-primary" type="submit">Siguiente</button>
                    </div>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </div>
    </form>
  );
};

export default Step_two;
