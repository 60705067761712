import React, { useEffect, useState } from "react";
import BasicLayout from "../../layout/BasicLayout/index";
import Loading from "../../components/Loading";
import HeadEncuesta from "../../components/HeadEncuesta";
import FormularioContrato from "../../components/FormularioContrato/FormularioContrato";
import { useHistory, useParams } from "react-router-dom";
import {
  obtenerFormulario
} from "../../_actions/formularioActions";
import "./ContratoDigital.scss";
import { useDispatch, useSelector } from "react-redux";
import {ToastsContainer, ToastsStore} from 'react-toasts';
const EncuestaDiscente = (props) => {
  const params = useParams();
  const { url } = params;
  const [activo, setActivo] = useState(false);
  const [datosSelect, setDatosSelect] = useState(null);
  const[redirigir,setRedirigir] = useState(false)
  const {loading} = useSelector(state=>state.formulario);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await dispatch(obtenerFormulario(url));
      setActivo(response.estado);
      const datos_select = {
      paises:response.paises,
      departamentos:response.departamentos,
      provincias:response.provincias,
      distritos: response.distritos}
     // console.log(datos_select)
      setDatosSelect(datos_select)
      setRedirigir(!response.estado);
      // ...
     // console.log(response.departamentos)
    }
    fetchData();
  }, []);
  
  useEffect(()=>{
    if(redirigir && !loading)
    {
       history.push(`/Error404`)
    }
   },[redirigir,loading])

  if (loading) return <Loading />;

  return (
    <BasicLayout
      header={false}
      title={"Contrato Digital"}
    >
      <div className="formulario-encuesta">
        <div className="main-encuesta shadow">
          <HeadEncuesta
            title={"Contrato"}
            descripcion={"Este contrato señala las condiciones bajo las cuales Gestora® brindará el servicio de capacitación."}
          />
          <div className="body-encuesta bg-white p-md-5 pt-md-1 p-2">
           { activo ? <FormularioContrato url={url} datos={datosSelect}/> : ''}
           <ToastsContainer store={ToastsStore}/>
          </div>
          <div className="foot">
            © {new Date().getFullYear()} Gestora &reg; Privacy & Cookies
          </div>
        </div>
      </div>
    </BasicLayout>
  );
};

export default EncuestaDiscente;
